import React, { memo, useState, useEffect } from 'react';
import axios from 'axios';
import {isEqual, pick} from 'lodash';

import { Alert, Space, Form, Typography, Checkbox, Row, Input, Col, Select, InputNumber, message, Divider, Card } from 'antd';
const { TextArea } = Input;
const { Text } = Typography;
const CheckboxGroup = Checkbox.Group;
import { InfoCircleOutlined } from '@ant-design/icons';

import endpoints from '../utils/endpoints';

import SubSectionDivider from './formComponents/SubSectionDivider'
import FormSection from './formComponents/FormSection';
import TranslationFormItem from './formComponents/TranslationFormItem';
import SubmitButton from './formComponents/SubmitButton';
import PlaceImageUpload from './formComponents/PlaceImageUpload';

const FormWrapper = ({
  uuid, 
  translations,
  translationLanguage,
  place,
  submission,
  setSubmitSuccess
}) => {
  const [form] = Form.useForm();
  const [formTranslations, setFormTranslations] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [hideCities, setHideCities] = useState(place?.geo_situation !== 'rural');
  const [messageApi, contextHolder] = message.useMessage()
  const [initialValues, setInitialValues] = useState({...place, ...submission?.updated_fields})
  const [onFocusValue, setOnFocusValue] = useState(null)
  const [imageFileList, setImageFileList] = useState([])
  const [loadingImages, setLoadingImages] = useState(false)
  
  // global trigger to hide certain form sections if the place is a network
  const placeTypeName = formTranslations?.place_types?.find(pt => pt.id === initialValues.type)?.name
  const networkDisplay = ["N", "I"].includes(placeTypeName) ? "none" : ""

  useEffect(() => {
    const fetchFormTranslations = async () => {
      try {
        const response = await axios.post(endpoints.formTranslations, {
          uuid,
          passcode: submission.passcode,
          languageCode: translationLanguage
        });
        setFormTranslations(response.data);
      } catch (error) {
        console.error('Error fetching translations:', error);
      }
    };

    fetchFormTranslations();
  }, [uuid, translationLanguage]);

  useEffect(() => {
    const fetchExistingImages = async () => {
      try {
        setLoadingImages(true);
        let response = await axios.post(endpoints.placeImages, { uuid, passcode: submission.passcode });
        let existingImages = response.data.images;
        setImageFileList(existingImages);
      } catch (e) {
        console.error('Error fetching images:', e);
      } finally {
        setLoadingImages(false);
      }
    };
    fetchExistingImages();
  }, []);

  const onFinish = async (values) => {
    setSubmitting(true);

    const submissionKeys = [
      "print_opt_in",
      "web_opt_in",
      "temp_opt_out",
      "submission_contact_name",
      "message"
    ]

    
    const submissionPatch = {
      ...pick(values, submissionKeys),
      full_form_data: JSON.stringify(values)
    }
    
    try {
      /* 
      when moving to a new edition, we are clearing the self_description values and store them to the old_description_values
      table. so when this form loads, the api fetches the latest self_description values and we are setting this as
      the initial value of the form item.
      it is important to the revision workflow that the self_description field is present in the updated_fields object
      because the emptied self_description has to be updated with this value.
      so we are sending an autosave of the current self_description value to the backend before submitting the form
      even if the value is the same as the initial value.
      */
      await axios.post(endpoints.autoSave, {
        columnName: "self_description",
        value: values?.self_description ?? null,
        uuid,
        passcode: submission.passcode
      })

      await axios.post(endpoints.submit, { uuid, submissionPatch, passcode: submission.passcode });
      setSubmitSuccess(true);
    } catch (error) {
      console.error('Error saving data:', error);
      setSubmitError(true);
    } finally {
      setSubmitting(false);
    }
  }

  async function autoSave ({
    columnName,
    value
  }) {
    //TODO: if value is the same of place[columName], remove the field from submission.updated_fields
    const oldValue = onFocusValue
    setOnFocusValue(null)
    if (!isEqual(value, oldValue) || value !== oldValue) {
      const response = await axios.post(endpoints.autoSave, {
        columnName,
        value,
        uuid,
        passcode: submission.passcode
      })
      const success = !!response?.data.success
      if (success) {
        messageApi.open({
          type: 'success',
          content: translations?.autoSaveSuccess,
        });
      } else {
        messageApi.open({
          type: 'error',
          content: translations?.autoSaveError,
        });
      }
      return { success }
    }
  }

  return (
    <Space direction="vertical" size="middle" style={{ display: 'flex', margin:'0px 20px 0px 20px' }}>
      {contextHolder}
      <Alert
        message={translations?.autoSaveNoticeHeader}
        description={translations?.autoSaveNoticeText}
        type="info" 
        showIcon 
      />
      <Form 
        labelCol={{ span: 10 }}
        labelWrap
        colon={false}
        labelAlign='left'
        initialValues={initialValues}
        form={form}
        onFinish={onFinish}
      >
        <FormSection 
          title={translations?.contactContainerHeading}
          children={
            <Row gutter={40}>
            <Col span={8}>
              <SubSectionDivider title={translations?.namingHeading} />
              <Form.Item
                label={translations?.placeName}
                name="name"
                rules={[{ required: true, message: ""}]}
              >
                <Input
                  placeholder=""
                  onFocus={(e) => setOnFocusValue(e.target.value)}
                  onBlur={async (e) => await autoSave({
                    columnName: 'name',
                    value: e.target.value
                  })}
                />
              </Form.Item>
              <Form.Item
                label={translations?.placeByname}
                name="byname"
                rules={[]}
              >
                <Input
                  placeholder=""
                  onFocus={(e) => setOnFocusValue(e.target.value)}
                  onBlur={async (e) => await autoSave({
                    columnName: 'byname',
                    value: e.target.value
                  })}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <SubSectionDivider title={translations?.addressHeading} />
              <Form.Item
                label={translations?.placeAddress}
                name="address"
                rules={[]}
              >
                <Input
                  placeholder=""
                  onFocus={(e) => setOnFocusValue(e.target.value)}
                  onBlur={async (e) => await autoSave({
                    columnName: 'address',
                    value: e.target.value
                  })}
                />
              </Form.Item>
              <Form.Item
                label={translations?.placePostalCode}
                name="postal_code"
              >
                <Input
                  placeholder=""
                  onFocus={(e) => setOnFocusValue(e.target.value)}
                  onBlur={async (e) => await autoSave({
                    columnName: 'postal_code',
                    value: e.target.value
                  })}
                />
              </Form.Item>
              <Form.Item
                label={translations?.placeCity}
                name="city"
                rules={[{ required: true, message: ""}]}
              >
                <Input
                  placeholder=""
                  onFocus={(e) => setOnFocusValue(e.target.value)}
                  onBlur={async (e) => await autoSave({
                    columnName: 'city',
                    value: e.target.value
                  })}
                />
              </Form.Item>
              <Form.Item 
                label={translations?.placeCountry}
                rules={[{ required: true, message: ""}]}
              >
                <Input 
                  value={formTranslations?.country}
                  readOnly={true}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <SubSectionDivider title={translations?.publicContactHeading} />
              <Form.Item
                label={translations?.placeTelephone}
                tooltip={translations?.telephonePlaceholder}
                name="telephone"
                rules={[]}
                >
                <Input
                  placeholder={translations?.telephonePlaceholder}
                  onFocus={(e) => setOnFocusValue(e.target.value)}
                  onBlur={async (e) => await autoSave({
                    columnName: 'telephone',
                    value: e.target.value
                  })}
                />
              </Form.Item>
              <Form.Item
                label={translations?.placePublicEmail}
                name="public_email"
                rules={[]}
              >
                <Input
                  placeholder=""
                  onFocus={(e) => setOnFocusValue(e.target.value)}
                  onBlur={async (e) => await autoSave({
                    columnName: 'public_email',
                    value: e.target.value
                  })}
                />
              </Form.Item>
              <Form.Item
                label={translations?.placeWebsite}
                name="website"
                rules={[]}
              >
                <Input
                  placeholder=""
                  onFocus={(e) => setOnFocusValue(e.target.value)}
                  onBlur={async (e) => await autoSave({
                    columnName: 'website',
                    value: e.target.value
                  })}
                />
              </Form.Item>
              <Form.Item
                label={translations?.placePublicMessengerNumber}
                name="public_messenger_number"
                tooltip={translations?.placePublicMessengerNumberTooltip}
                rules={[]}
                >
                <Input
                  placeholder={translations?.placePublicMessengerNumberTooltip}
                  onFocus={(e) => setOnFocusValue(e.target.value)}
                  onBlur={async (e) => await autoSave({
                    columnName: 'public_messenger_number',
                    value: e.target.value
                  })}
                />
              </Form.Item>
              <Form.Item
                label={translations?.placePublicMessengerService}
                tooltip={translations?.placePublicMessengerServiceTooltip}
                name="public_messenger_service"
                rules={[]}
                >
                <Input
                  placeholder={translations?.placePublicMessengerServiceTooltip}
                  onFocus={(e) => setOnFocusValue(e.target.value)}
                  onBlur={async (e) => await autoSave({
                    columnName: 'public_messenger_service',
                    value: e.target.value
                  })}
                />
              </Form.Item>
            </Col>
          </Row>
          }
        />
        <FormSection
          title={translations?.placeInfoContainerHeading}
          children={
            <Row style={{flexDirection: "column"}}>
              <Row style={{flexDirection: "column", display: networkDisplay}}>
                <Row style={{flexDirection:"row"}} gutter={40}>
                <Col span={8}>
                  <SubSectionDivider title={translations?.communityInhabitants} />
                  <Row gutter={40}>
                    <Col span={24}>
                    <Form.Item 
                        label={translations?.communityMenCount}
                        name="men_count"
                        tooltip={translations?.countTooltip}
                      >
                        <InputNumber 
                          placeholder=""
                          onFocus={(e) => setOnFocusValue(e.target.value)}
                          onBlur={async (e) => await autoSave({
                            columnName: 'men_count',
                            value: parseInt(e.target.value)
                          })}
                        />
                      </Form.Item>
                      <Form.Item 
                        label={translations?.communityWomenCount}
                        name="women_count"
                        tooltip={translations?.countTooltip}
                      >
                        <InputNumber 
                          placeholder=""
                          onFocus={(e) => setOnFocusValue(e.target.value)}
                          onBlur={async (e) => await autoSave({
                            columnName: 'women_count',
                            value: parseInt(e.target.value)
                          })}
                        />
                      </Form.Item>
                      <Form.Item 
                        label={translations?.communityUnder18Count}
                        name="under18_count"
                        tooltip={translations?.countTooltip}
                      >
                        <InputNumber 
                          placeholder=""
                          onFocus={(e) => setOnFocusValue(e.target.value)}
                          onBlur={async (e) => await autoSave({
                            columnName: 'under18_count',
                            value: parseInt(e.target.value)
                          })}
                        />
                      </Form.Item>
                      <Form.Item 
                        label={translations?.communityTotalPeopleCount}
                        name="total_people_count"
                        >
                        <InputNumber 
                          placeholder={translations?.communityTotalPeopleCountPlaceholder}
                          onFocus={(e) => setOnFocusValue(e.target.value)}
                          onBlur={async (e) => await autoSave({
                            columnName: 'total_people_count',
                            value: parseInt(e.target.value)
                          })}
                        />
                      </Form.Item>
                      <Form.Item 
                        label={translations?.communityOpenToNewMembers}
                        name="open_to_new_members"
                        rules={[]}
                        valuePropName='checked'
                        >
                        <Checkbox
                          onChange={async (e) => await autoSave({
                            columnName: 'open_to_new_members',
                            value: e.target.checked
                          })}
                        />
                      </Form.Item>
                    </Col> 
                  </Row>
                </Col>
                  <Col span={8}>
                    <SubSectionDivider title={translations?.communityGeoSituation} />
                    <Form.Item 
                    label={translations?.communityGeoSituation}
                    name="geo_situation"
                    rules={[]}
                    >
                    <Select
                      options={[
                        { value: 'rural', label: translations?.communityGeoSituationRural },
                        { value: 'city', label: translations?.communityGeoSituationCity },
                        { value: 'town', label: translations?.communityGeoSituationTown },
                      ]}
                      onChange={async (value) => {
                        await autoSave({
                          columnName: 'geo_situation',
                          value
                        });
                        if (value !== 'rural') {
                          setHideCities(true);
                        } else {
                          setHideCities(false);
                        }
                      }}
                    />
                    </Form.Item>
                    {hideCities ? null : (
                      <Form.Item
                        label={translations?.placeCities}
                        name="cities"
                        rules={[]}
                      >
                        <Input
                          placeholder=""
                          onBlur={async (e) => await autoSave({
                            columnName: 'cities',
                            value: e.target.value
                          })}
                        />
                      </Form.Item>
                    )}
                    <SubSectionDivider title={translations?.networks} />
                    <Card size="small" style={{marginBottom:"12px"}}>
                      <Space size={"middle"} direction="horizontal">
                        <InfoCircleOutlined />
                        
                          {initialValues?.linked_networks === null ?
                            translations?.communityNoLinkedNetworks
                            :
                            translations?.communityHasNetworks?.replace("{{networks}}", initialValues?.linked_networks?.map(n => n.display_name)?.join(", "))
                          }
                      </Space>
                    </Card>
                    <Form.Item
                      name='other_networks'
                      tooltip={""}
                    >
                      <TranslationFormItem
                        nativeLanguage={translationLanguage}
                        minRows={2}
                        maxRows={4}
                        translations={translations}
                        autoSave={autoSave}
                        columnName='other_networks'
                        placeholderTranslationKey=""
                        hideExamples={true}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <SubSectionDivider title={translations?.communityMisc} />
                    <Form.Item 
                      label={translations?.communityStatus}
                      name="status"
                    >
                      <Select
                        status="warning"
                        options={[
                          { value: formTranslations?.status?.find(s => s.name === "existing").id, label: translations?.communityStatusExisting },
                          { value: formTranslations?.status?.find(s => s.name === "planned").id, label: translations?.communityStatusPlanned },
                          { value: formTranslations?.status?.find(s => s.name === "unknown").id, label: translations?.communityStatusUnknown }
                        ]}
                        onChange={async (value) => {
                          await autoSave({
                            columnName: 'status',
                            value
                          });
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      label={translations?.communityExistsSinceYear}
                      name="exists_since_year"
                    >
                      <InputNumber
                        placeholder=""
                        onFocus={(e) => setOnFocusValue(e.target.value)}
                        onBlur={async (e) => await autoSave({
                          columnName: 'exists_since_year',
                          value: parseInt(e.target.value)
                        })}
                      />
                    </Form.Item>
                    <Form.Item
                      label={translations?.communityWorkingGuests}
                      name="working_guest_conditions"
                      tooltip={translations?.workingGuestsTooltip}
                      rules={[]}
                    >
                      <Select
                        options={[
                          { value: 'no', label: translations?.workingGuestsNo },
                          { value: 'yes', label: translations?.workingGuestsYes },
                          { value: 'food_and_shelter', label: translations?.workingGuestsFoodAndShelter },
                        ]}
                        onChange={async (value) => await autoSave({
                          columnName: 'working_guest_conditions',
                          value
                        })}
                      />
                    </Form.Item>
                    <Form.Item
                      label={translations?.communityPublications}
                      name="own_publications"
                      tooltip={translations?.communityPublicationsTooltip}
                    >
                      <Input
                        placeholder=""
                        onFocus={(e) => setOnFocusValue(e.target.value)}
                        onBlur={async (e) => await autoSave({
                          columnName: 'own_publications',
                          value: e.target.value
                        })}
                      />
                    </Form.Item>
                    <Form.Item
                      label={translations?.ecovillage}
                      name="is_ecovillage"
                      tooltip={translations?.ecovillageTooltip}
                      valuePropName='checked'
                      >
                      <Checkbox
                        onChange={async(e) => {
                          await autoSave({
                            columnName: "is_ecovillage",
                            value: e.target.checked
                          })
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Row>
              <Row style={{marginTop: "10px", marginBottom: "15px"}}>
                  <Alert message={translations?.communityTextTranslationDisclaimer} type="info" showIcon style={{ width: '100%' }} />
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      labelCol={{ span: 4 }}
                      name='self_description'
                      label={
                        <Space direction="vertical">
                          <Text>{translations?.communitySelfDescription}</Text>
                          {
                            place?.self_description_year &&
                            <Text type="secondary">
                              {translations?.communitySelfDescriptionYearNote?.replace("{{year}}", place?.self_description_year)}
                            </Text>
                          }
                        </Space>
                      }
                    >
                      <TranslationFormItem
                        nativeLanguage={translationLanguage}
                        minRows={10}
                        maxRows={20}
                        translations={translations}
                        autoSave={autoSave}
                        columnName='self_description'
                        placeholderTranslationKey={"communitySelfDescriptionPlaceholder"}
                      />
                    </Form.Item>
                    <Divider/>
                  </Col>
                </Row>
                <div style={{display: networkDisplay}}>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        labelCol={{ span: 4 }}
                        name='principles'
                        label={translations?.communityPrinciples}
                        tooltip={translations?.communityPrinciplesTooltip}
                      >
                        <TranslationFormItem
                          nativeLanguage={translationLanguage}
                          minRows={5}
                          maxRows={10}
                          translations={translations}
                          autoSave={autoSave}
                          columnName='principles'
                          placeholderTranslationKey={"communityPrinciplesPlaceholder"}
                        />
                      </Form.Item>
                      <Divider />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        labelCol={{ span: 4 }}
                        name='conditions'
                        label={translations?.communityConditions}
                      >
                        <TranslationFormItem
                          nativeLanguage={translationLanguage}
                          minRows={5}
                          maxRows={10}
                          translations={translations}
                          autoSave={autoSave}
                          columnName='conditions'
                          placeholderTranslationKey={"communityConditionsPlaceholder"}
                        />
                      </Form.Item>
                      <Divider />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        labelCol={{ span: 4 }}
                        name='decision_group'
                        label={translations?.communityDecisionGroup}
                        tooltip={translations?.communityDecisionGroupTooltip}
                      >
                        <TranslationFormItem
                          nativeLanguage={translationLanguage}
                          minRows={5}
                          maxRows={10}
                          translations={translations}
                          autoSave={autoSave}
                          columnName='decision_group'
                          placeholderTranslationKey={"communityDecisionGroupPlaceholder"}
                        />
                      </Form.Item>
                      <Divider />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        labelCol={{ span: 4 }}
                        name='decision_method'
                        label={translations?.communityDecisionMethod}
                        tooltip={translations?.communityDecisionMethodTooltip}
                      >
                        <TranslationFormItem
                          nativeLanguage={translationLanguage}
                          minRows={5}
                          maxRows={10}
                          translations={translations}
                          autoSave={autoSave}
                          columnName='decision_method'
                          placeholderTranslationKey={"communityDecisionMethodPlaceholder"}
                        />
                      </Form.Item>
                      <Divider />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        labelCol={{ span: 4 }}
                        name='economy'
                        label={translations?.communityEconomy}
                        tooltip={translations?.communityEconomyTooltip}
                      >
                        <TranslationFormItem
                          nativeLanguage={translationLanguage}
                          minRows={5}
                          maxRows={10}
                          translations={translations}
                          autoSave={autoSave}
                          columnName='economy'
                          placeholderTranslationKey={"communityEconomyPlaceholder"}
                        />
                      </Form.Item>
                      <Divider />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        labelCol={{ span: 4 }}
                        name='work_fields'
                        label={translations?.communityWorkFields}
                      >
                        <TranslationFormItem
                          nativeLanguage={translationLanguage}
                          minRows={1}
                          maxRows={4}
                          translations={translations}
                          autoSave={autoSave}
                          columnName='work_fields'
                          placeholderTranslationKey={"communityWorkFieldsPlaceholder"}
                        />
                      </Form.Item>
                      <Divider />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        labelCol={{ span: 4 }}
                        name='size'
                        label={translations?.communitySize}
                        tooltip={translations?.communitySizeTooltip}
                      >
                        <TranslationFormItem
                          nativeLanguage={translationLanguage}
                          minRows={1}
                          maxRows={4}
                          translations={translations}
                          autoSave={autoSave}
                          columnName='size'
                          placeholderTranslationKey={"communitySizePlaceholder"}
                        />
                      </Form.Item>
                      <Divider />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        labelCol={{ span: 4 }}
                        name='owned_by'
                        label={translations?.communityOwnedBy}
                        tooltip={translations?.communityOwnedByTooltip}
                      >
                        <TranslationFormItem
                          nativeLanguage={translationLanguage}
                          minRows={5}
                          maxRows={10}
                          translations={translations}
                          autoSave={autoSave}
                          columnName='owned_by'
                          placeholderTranslationKey={"communityOwnedByPlaceholder"}
                        />
                      </Form.Item>
                      <Divider />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        labelCol={{ span: 4 }}
                        name='self_sufficiency'
                        label={translations?.communitySelfSufficiency}
                      >
                        <TranslationFormItem
                          nativeLanguage={translationLanguage}
                          minRows={1}
                          maxRows={4}
                          translations={translations}
                          autoSave={autoSave}
                          columnName='self_sufficiency'
                          placeholderTranslationKey={"communitySelfSufficiencyPlaceholder"}
                        />
                      </Form.Item>
                      <Divider />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        labelCol={{ span: 4 }}
                        name='dietary_practice'
                        label={translations?.communityDietaryPractice}
                        tooltip={translations?.communityDietaryPracticeTooltip}
                      >
                        <TranslationFormItem
                          nativeLanguage={translationLanguage}
                          minRows={1}
                          maxRows={4}
                          translations={translations}
                          autoSave={autoSave}
                          columnName='dietary_practice'
                          placeholderTranslationKey={"communityDietaryPracticePlaceholder"}
                        />
                      </Form.Item>
                      <Divider />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        labelCol={{ span: 4 }}
                        name='language_skills'
                        label={translations?.communityLanguageSkills}
                      >
                        <TranslationFormItem
                          nativeLanguage={translationLanguage}
                          minRows={1}
                          maxRows={4}
                          translations={translations}
                          autoSave={autoSave}
                          columnName='language_skills'
                          placeholderTranslationKey={"communityLanguageSkillsPlaceholder"}
                        />
                      </Form.Item>
                      <Divider />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        labelCol={{ span: 4 }}
                        name='people_working_in_common_businesses'
                        label={translations?.communityPeopleWorkingInCommonBusinesses}
                        tooltip={translations?.communityPeopleWorkingInCommonBusinessesTooltip}
                      >
                        <TranslationFormItem
                          nativeLanguage={translationLanguage}
                          minRows={1}
                          maxRows={4}
                          translations={translations}
                          autoSave={autoSave}
                          columnName='people_working_in_common_businesses'
                          placeholderTranslationKey={"communityPeopleWorkingInCommonBusinessesPlaceholder"}
                        />
                      </Form.Item>
                      <Divider />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        labelCol={{ span: 4 }}
                        name='people_working_on_community_grounds'
                        label={translations?.peopleWorkingOnCommunityGrounds}
                        tooltip={translations?.peopleWorkingOnCommunityGroundsTooltip}
                      >
                        <TranslationFormItem
                          nativeLanguage={translationLanguage}
                          minRows={1}
                          maxRows={4}
                          translations={translations}
                          autoSave={autoSave}
                          columnName='people_working_on_community_grounds'
                          placeholderTranslationKey={"peopleWorkingOnCommunityGroundsPlaceholder"}
                        />
                      </Form.Item>
                      <Divider />
                    </Col>
                  </Row>
                </div>
                <Row style={{display: networkDisplay}}>
                  <Col span={24}>
                    <Form.Item
                      labelCol={{ span: 4 }}
                      name='keywords'
                      label={translations?.keywords}
                      >
                        <CheckboxGroup
                          onChange={async (values) => await autoSave({
                            uuid,
                            columnName: 'keywords',
                            value: values
                          })}
                        >
                          <Row>
                            {formTranslations?.keywords?.map(k => (
                              <Col 
                                span={8}
                                key={k.id}
                              >
                                <Checkbox 
                                  value={k.id}
                                  key={k.id}
                                >
                                  {k.name}
                                </Checkbox>
                              </Col>
                            ))}
                          </Row>
                        </CheckboxGroup>
                      </Form.Item>
                      <Divider />
                  </Col>
                </Row>
                <Row style={{display: networkDisplay}}>
                  <Col span={24}>
                    <Form.Item
                      labelCol={{ span: 4 }}
                      name='custom_keywords'
                      label={translations?.communityKeywords}
                      tooltip={translations?.communityKeywordsTooltip}
                    >
                      <TranslationFormItem
                        nativeLanguage={translationLanguage}
                        minRows={5}
                        maxRows={10}
                        translations={translations}
                        autoSave={autoSave}
                        columnName='custom_keywords'
                        placeholderTranslationKey={"communityKeywordsPlaceholder"}
                      />
                    </Form.Item>
                    <Divider />
                  </Col>
                </Row>
              </Row>
          }
        />
        <FormSection
          title={translations?.uploadImages}
          children={
            <PlaceImageUpload
              translations={translations}
              uuid={uuid}
              passcode={submission.passcode}
              message={message}
              placeId={place.id}
              imageFileList={imageFileList}
              setImageFileList={setImageFileList}
            />
          }
        />
        <FormSection
          title={translations?.important}
          isImportant
          children={
            <Row gutter={40}>
              <Col span={8}>
                <SubSectionDivider title={translations?.publicationPreferences} />
                <Alert message={translations?.publicationConsent} type="info" />
                <Form.Item 
                  label={translations?.webOptIn}
                  tooltip={translations?.publishOnAWebsite}
                  valuePropName='checked'
                  name="web_opt_in"
                  initialValue={true}
                  >
                  <Checkbox
                    defaultChecked={true}
                  />
                </Form.Item>
                <Form.Item 
                  label={translations?.printOptIn}
                  tooltip={translations?.publishInABook}
                  valuePropName='checked'
                  name="print_opt_in"
                  initialValue={true}
                >
                  <Checkbox
                    defaultChecked={true}
                  />
                </Form.Item>
                <Divider/>
                <Form.Item 
                  label={translations?.tmpOptOut}
                  tooltip={`⚠️ ${translations?.tempOptOutTooltip}`}
                  name="temp_opt_out"
                  valuePropName='checked'
                  initialValue={_.isNull(submission?.temp_opt_out) ? false : submission?.temp_opt_out}
                >
                  <Checkbox/>
                </Form.Item>
              </Col>
              <Col span={8}>
                <SubSectionDivider title={translations?.communicationPreferences} />
                  <Form.Item
                    label={translations?.placeContactEmail}
                    name="contact_emails"
                    rules={[{ required: true, message: '' }]}
                  >
                    <Input
                      placeholder=""
                      onFocus={(e) => setOnFocusValue(e.target.value)}
                      onBlur={async (e) => await autoSave({
                        columnName: 'contact_emails',
                        value: e.target.value
                      })}
                    />
                  </Form.Item>
                  <Form.Item
                    label={translations?.placeContactName}
                    name="contact_name"
                    rules={[{ required: true, message: 'Please enter contact name' }]}
                  >
                    <Input
                      placeholder=""
                      onFocus={(e) => setOnFocusValue(e.target.value)}
                      onBlur={async (e) => await autoSave({
                        columnName: 'contact_name',
                        value: e.target.value
                      })}
                    />
                  </Form.Item>
                  <Form.Item
                    label={(translationLanguage !== formTranslations?.languages?.find(l => l.id === place.language)?.code ? "⚠️ " : "") + translations?.correspondenceLanguage}
                    name="language"
                    rules={[{ required: true, message: '' }]}
                  >
                    <Select
                      options={[
                        { value: formTranslations?.languages?.find(l => l.code === "de")?.id, label: 'Deutsch' },
                        { value: formTranslations?.languages?.find(l => l.code === "en")?.id, label: 'English' },
                        { value: formTranslations?.languages?.find(l => l.code === "es")?.id, label: 'Español' },
                        { value: formTranslations?.languages?.find(l => l.code === "fr")?.id, label: 'Français' },
                        { value: formTranslations?.languages?.find(l => l.code === "it")?.id, label: 'Italiano' }
                      ]}
                      onChange={async (value) => {
                        await autoSave({
                          columnName: 'language',
                          value
                        });
                      }}
                    />
                  </Form.Item>
              </Col>
              <Col span={8}>
                <SubSectionDivider title={translations?.submissionContactHeader} />
                <Form.Item
                  label={translations?.submissionContactName}
                  name="submission_contact_name"
                  tooltip={translations?.submissionContactNameTooltip}
                  initialValue={submission?.submission_contact_name}
                >
                  <Input/>
                </Form.Item>
                <Form.Item
                  name="message"
                  label={translations?.yourMessage}
                  initialValue={submission?.message}
                >
                  <TextArea
                    placeholder=""
                    autoSize={{ minRows: 5, maxRows: 12 }}
                  />
                </Form.Item>
              </Col>
            </Row>
          }
        />
        <Form.Item>
          <Row style={{flexDirection: "column"}}>
            <Col span={24}>
              <SubmitButton 
                messageApi={messageApi} 
                form={form} 
                submitting={submitting}
                translations={translations}
                onFinish={onFinish}
              >
                {translations?.submit}
              </SubmitButton>
            </Col>
            <Col span={24}>
              <Alert 
                type="error" 
                message={translations?.submitError} 
                showIcon 
                style={{ 
                  marginTop:"15px", 
                  width: '100%', 
                  visibility: submitError ? 'visible' : 'hidden' 
                }} 
              />
            </Col>
          </Row>
      </Form.Item>
      </Form>
    </Space>
  );
};

export default memo(FormWrapper);
